<template>
  <el-dialog
    class="add-exam"
    title="添加考试"
    :visible.sync="addExamDialog"
    :before-close="cancel"
    :close-on-click-modal="false"
    width="600px"
  >
    <el-form :model="addExamForm" ref="dataForm" :rules="addExamFormRules" label-width="120px">
      <el-form-item label="考试名称" prop="examName">
        <el-input
          v-model="addExamForm.examName"
          clearable
          placeholder="请输入考试名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="试卷名称" prop="testPaperInfoId">
        <el-select
          style="width: 100%"
          v-model="addExamForm.testPaperInfoId"
          clearable
          placeholder="请选择">
          <el-option
            v-for="item in examTestList"
            @click.native="_selectPaperName(item)"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="答题时长" class="time" prop="duration">
        <el-input
          v-model="addExamForm.duration"
          clearable
          placeholder="请输入答题时长"
        >
          <template slot="append"><span>分钟</span></template>
        </el-input>
      </el-form-item>
      <el-form-item label="考试通过分数线" class="time">
        <el-input
          v-model="addExamForm.gradeThreshold"
          @input="_inputPaperMark(addExamForm.gradeThreshold)"
          clearable
          placeholder="请输入考试通过分数线"
        >
          <template slot="append"><span>分</span></template>
        </el-input>
        <div style="font-size: 12px; color: #999999">试卷总分为{{paperTotalMark}}分</div>
      </el-form-item>
      <el-form-item label="考试时间">
        <el-date-picker
          style="width: 100%"
          v-model="addExamForm.examTime"
          clearable
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="_selectExamTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="关联课程" prop="introduce">
        <el-select
          v-model="addExamForm.planCourseIds"
          multiple

          clearable
          style="width: 100%"
          placeholder="请选择"
        >
          <el-option
            v-for="item in relevanceCourseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试介绍" prop="introduce">
        <el-input
          v-model="addExamForm.introduce"
          type="textarea"
          autosize
          clearable
          placeholder="请输入考试介绍"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="答案解析设置" prop="analysisType">
        <el-radio-group v-model="addExamForm.analysisType">
          <el-radio :label="2">不允许查看</el-radio>
          <el-radio :label="1">考试完成后</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="允许查看试卷">
        <el-radio-group  v-model="addExamForm.examineFlag">
          <el-radio :label="1">考试完成后</el-radio>
          <el-radio :label="2">批阅完成后</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="阅卷人" prop="examerType">
        <el-radio-group v-model="addExamForm.examerType" @input="_selectExamerType">
          <el-radio :label="1">学习计划创建人</el-radio>
          <el-radio :label="2">指定人员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="addExamForm.examerType == 2" prop="examiners">
        <!-- <el-select
          value-key="examinerId"
          multiple
          v-model="addExamForm.examiners"
          placeholder="请选择"
          clearable
          filterable
          style="width: 274px"
        >
          <el-option
            v-for="item in userDeptList"
            :key="item.examinerId"
            :label="item.examiner"
            :value="item"
          >
          </el-option>
        </el-select> -->
        <el-select
              style="width:70%;"
              filterable
              value-key="examinerId"
              multiple
              v-model="addExamForm.examiners"
              placeholder="请选择">
              <el-option
                v-for="item in userDeptList"
                :key="item.examinerId"
                :label="item.examiner"
                :value="item"
                @click.native="_selectMarkUser(item)"/>
            </el-select>
      </el-form-item>
      <div class="el-form-item el-form-item--small">
        <label for="reminderMinutes" class="el-form-item__label" style="width: 120px;">
          <el-checkbox v-model="reminderMinutesChecked"></el-checkbox>
        </label>
        <div class="el-form-item__content" style="margin-left: 120px;">
          <span>考试前
            <el-select
              :disabled="!reminderMinutesChecked"
              style="width:65px;"
              v-model="addExamForm.reminderMinutes"
              filterable
              allow-create
              placeholder="请选择">
              <el-option
                v-for="item in examRemindTimeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            分钟提醒</span>
        </div>
      </div>
      <el-form-item label="显示考试成绩" prop="markShowFlag">
        <el-radio-group  v-model="addExamForm.markShowFlag"  style="width: 70%;">
          <el-radio :label="1">批阅完成后</el-radio>
          <el-radio :label="2">考试完成显示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否允许切屏" prop="cutScreenFlag">
        <el-radio-group  v-model="addExamForm.cutScreenFlag"  style="width: 70%;" @input="_changeCutScreenRadio">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="允许切屏次数" v-if="addExamForm.cutScreenFlag==1">
        <el-input style="width: 70%;"  v-model="addExamForm.cutScreenNum" placeholder="请输入允许切屏次数" clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$parent.addExamDialog = false">取 消</el-button>
      <el-button type="primary" @click="addEmax">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addExamInfoApi, getPaperListApi, getHistoryMarkUserApi } from '@/api/examApi.js'
import { getDartmentApi, getUserListApi } from '@/api/api.js'
import { createOrUpdateApi, getRelevanceCourseApi } from '@/api/study.js'
import { getCurrentTime } from '@/utils/util'
export default {
  props: {
    addExamDialog: {
      type: Boolean,
      default: false
    },
    currentItem: {
      type: Object,
      default: () => {}
    },
    dataInfo: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      reminderMinutesChecked: false,
      addExamForm: {
        examName: '',
        testPaperInfoId: '',
        testPaperName: '',
        duration: '',
        gradeThreshold: '',
        examTime: [],
        startTime: '',
        endTime: '',
        introduce: '',
        analysisType: '',
        examineFlag: '',
        examerType: '',
        examerId: '',
        examer: '',
        reminderMinutes: 5,
        cutScreenFlag: null, // 是否允许切屏
        cutScreenNum: null,
        markShowFlag: null, // 显示考试成绩
        examiners: [] // 审阅人员
      },
      examTestList: [], // 试卷列表
      userDeptList: [], // 用户
      relevanceCourseList: [], // 关联课程
      paperTotalMark: 100, // 试卷总分
      addExamFormRules: {
        examName: [
          { required: true, message: '请输入考试名称', trigger: 'blur' }
        ],
        testPaperInfoId: [
          { required: true, message: '请选择试卷名称', trigger: 'change' }
        ],
        duration: [
          { required: true, message: '请输入答题时长', trigger: 'change' }
        ],
        examerType: [
          { required: true, message: '请选择阅卷人', trigger: 'change' }
        ],
        examiners: [{ required: true, message: '请选择阅卷人', trigger: ['blur', 'change'] }],
        cutScreenFlag: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
        markShowFlag: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
      },
      examRemindTimeArr: [{
        value: 5,
        label: 5
      }, {
        value: 10,
        label: 10
      }, {
        value: 15,
        label: 15
      }, {
        value: 20,
        label: 20
      }, {
        value: 30,
        label: 30
      }]
    }
  },
  created () {
    this._getPaperList()
    this._getAllUserList()
    this.getRelevanceCourse()
  },
  methods: {
    // 选择阅卷人
    _selectMarkUser (item) {
      this.arrangeForm.examerId = item.examinerId// 审阅人员id
      this.arrangeForm.examer = item.examiner// 审阅人员name
    },
    cancel () {
      this.$parent.addExamDialog = false
    },
    // 获取试卷列表
    _getPaperList () {
      // 查询已发布的试卷
      getPaperListApi({ status: 1, page: 1, pageSize: 9999, testPaperGroupId: 0 }).then((res) => {
        if (res.code === 200) {
          this.examTestList = res.object.results
        }
      })
    },
    // 获取组织架构人员
    _getAllUserList () {
      getUserListApi({ pageSize: 0, userPostStatus: 4 }).then((res) => {
        this.userDeptList = JSON.parse(JSON.stringify(res.data.data))
        this.userDeptList = this.userDeptList.map((item) => {
          return {
            examinerId: item.userId,
            examiner: item.userName
          }
        })
      })
    },
    getRelevanceCourse () {
      // 获取关联课程
      getRelevanceCourseApi({
        planId: this.$route.query.id,
        stageId: this.currentItem.stageId
      }).then(res => {
        this.relevanceCourseList = res.data
      })
    },
    // 选择试卷
    _selectPaperName (item) {
      this.addExamForm.gradeThreshold = null
      this.addExamForm.testPaperInfoId = item.id
      this.addExamForm.testPaperName = item.name
      this.paperTotalMark = item.mark
    },
    // 考试通过分数线--打分
    _inputPaperMark (mark) {
      // 限制考试通过分数线最大值和最小值-start
      if (mark !== undefined && mark !== null && mark !== '') {
        if (Number(mark) > Number(this.paperTotalMark)) {
          this.$set(this.addExamForm, 'gradeThreshold', Number(this.paperTotalMark))
        }
        if (Number(mark) < 0) {
          this.$set(this.addExamForm, 'gradeThreshold', 0)
        }
      }
    },
    // 选择考试时间
    _selectExamTime (a) {
      if (a) {
        const examStartTime = new Date(a[0]).getTime()// 考试设置的开始时间
        const examEndTime = new Date(a[1]).getTime()// 考试设置的截止时间
        const currentTime = new Date(getCurrentTime()).getTime()// 当前时间
        if (examStartTime < currentTime || examEndTime < currentTime) {
          this.examTime = ''
          return this.$message.error('考试开始和结束时间不能小于当前时间！')
        }
        this.addExamForm.startTime = a[0]
        this.addExamForm.endTime = a[1]
      } else {
        this.addExamForm.startTime = ''
        this.addExamForm.endTime = ''
      }
    },
    // 选择阅卷人类型
    _selectExamerType (e) {
      if (e === 1) {
        this.addExamForm.examerId = this.$store.state.userList.userId// 审阅人员id
        this.addExamForm.examer = this.$store.state.userList.userName// 审阅人员name
      } else {
        this.addExamForm.examerId = ''// 审阅人员id
        this.addExamForm.examer = ''// 审阅人员name
        this.addExamForm.examiners = []
        // 获取上次历史数据-- - 进行回显
        getHistoryMarkUserApi().then((res) => {
          if (res.code === 200) {
            console.log(res.data, 'res.data')
            this.addExamForm.examiners = res.data
          }
        })
      }
    },
    // 选择是否允许切屏
    _changeCutScreenRadio (e) {
      if (e === 1) {
        this.addExamForm.cutScreenNum = 3
      }
    },
    // 添加考试
    addEmax () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.addExamForm.examerType === 1) { // 当阅卷人为考试创建人
            this.addExamForm.examiners = [{
              examinerId: this.$store.state.userList.userId,
              examiner: this.$store.state.userList.userName
            }]
          }
          if (this.addExamForm.duration) {
            const examStartTime = new Date(this.addExamForm.startTime).getTime()// 考试设置的开始时间
            const examEndTime = new Date(this.addExamForm.endTime).getTime()// 考试设置的截止时间
            const differentTime = examEndTime - examStartTime
            const durationTime = Number(this.addExamForm.duration) * 60 * 1000
            if (differentTime < durationTime) {
              return this.$message.error('考试时间设置的区间不可小于考试时长！')
            }
          }
          this.addExamForm = {
            ...this.addExamForm,
            examineFlag: this.addExamForm.examineFlag ? this.addExamForm.examineFlag : 1, // 默认允许查看试卷--不允许查看
            startTime: this.addExamForm.examTime
              ? this.addExamForm.examTime[0]
              : '',
            endTime: this.addExamForm.examTime ? this.addExamForm.examTime[1] : '',
            reminderMinutes: this.reminderMinutesChecked ? this.addExamForm.reminderMinutes : ''
          }
          this.examTestList.forEach(item => {
            if (item.id === this.addExamForm.testPaperInfoId) {
              this.addExamForm.testPaperName = item.name
            }
          })
          this.addExamForm.useDeptId = this.dataInfo.useDeptId
          this.addExamForm.useDeptName = this.dataInfo.useDeptName
          this.addExamForm.useType = this.dataInfo.useType

          this.addExamForm.examer = this.dataInfo.createName
          this.addExamForm.examerId = this.dataInfo.createBy

          addExamInfoApi({ ...this.addExamForm }).then((res) => {
            if (res.code === 200) {
              const obj = {
                type: 3,
                id: this.addExamForm.id,
                stageId: this.currentItem.stageId,
                studyPlanId: this.$route.query.id,
                planCourseIds: this.addExamForm.planCourseIds,
                studyPlanCourseList: [{

                  ...res.data,
                  id: null,
                  startTime: res.data.startTime ? res.data.startTime + ':00' : '',
                  endTime: res.data.endTime ? res.data.endTime + ':00' : '',
                  relevanceId: res.data.id,
                  name: res.data.examName
                }]
              }

              this.addCourseApi(obj)
            }
          })
        }
      })
    },
    // 添加阶段安排接口调用
    addCourseApi (params) {
      createOrUpdateApi(params).then((res) => {
        if (res.code === 200) {
          this.$parent.addExamDialog = false
          this.$parent.getClassList()
          this.$message.success('操作成功！')
        }
      })
    },
    // 根据部门获取用户
    _getDeptIduser () {
      getDartmentApi({
        pageSize: 0,
        userDeptId: this.$store.state.userList.deptId,
        userPostStatus: 4
      }).then((res) => {
        this.userDeptList = res.data.data
      })
    }
    // // 指定人员选中
    // handlerClickExamer (even) {
    //   this.userDeptList.forEach((item) => {
    //     if (item.userId === even) {
    //       this.addExamForm.examer = item.userName
    //       this.addExamForm.examerId = item.userId
    //     }
    //   })
    // }
  }
}
</script>

<style></style>
